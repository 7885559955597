@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E2EEF8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  margin: 0;
  font-family: "Titillium Web", sans-serif !important;
  /* font-weight: 400;
  font-style: normal; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background-color: #e2eef8;
}

code {
  font-family: "Titillium Web", sans-serif !important;
  /* font-weight: 400;
  font-style: normal; */
}

.navBar {
  /* height: 82px; */
  height: 78px;
  box-shadow: none !important;
  background-image: url("assets/img/logo.png");
  background-repeat: no-repeat;
  background-position: right;
  border-bottom: 5px solid;
  /* border-image:   linear-gradient(to right, red 33%, white 33%, white 67%, green 67%, green 100%) 2 */
  border-image: linear-gradient(
      to right,
      green 33%,
      white 33%,
      white 67%,
      red 67%,
      red 100%
    )
    2;
  background-color: #0066cc !important;
}

.cardDetail {
  background-color: white;
  background-image: url("assets/img/sfondo.png");
  background-size: 400px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding: 20px;
}

.cardList {
  position: relative;
  background-color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0px 10px 0px;
  margin-top: 10px;
}

.iconCheck {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-50%, -50%);
}

.cardQuestion {
  background-color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding: 20px;
  height: 220px;
}

.cardResult {
  background-color: white;
  border-radius: 20px;
  background-image: url("assets/img/sfondo.png");
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 255px;
}

.bold-text {
  font-weight: 600 !important;
}

.normal-text {
  font-weight: 400 !important;
}

.thin-text {
  font-weight: 300 !important;
}

.result-header-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.cardResultWithData {
  background-color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 255px;
}

.result-progress {
  width: 100%;
}

.risultati-header-text-button {
  display: flex;
}

.login-section {
  background-color: white;
  border-radius: 20px;
  background-image: url("assets/img/sfondo.png");
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
